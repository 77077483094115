import React from "react";
import Layout from "../components/layout";
import { Container, Row, Col } from "react-bootstrap";
import Seo from "../components/seo";
//Images
import { StaticImage } from "gatsby-plugin-image";

const Builders = () => {
    return (
        <Layout>
             <Seo
      title="Wohnungsbau - BIKESAFE"
      description="Dank der kompakten Produkte mit dem günstigen Preis-Leistungs-Verhältnis von Bike Safe muss die Unterbringung von Fahrrädern den Wohnungsbauunternehmen ab sofort kein Kopfzerbrechen mehr bereiten. Sprechen Sie mit einem unserer Vertreter, der Ihnen die Lösung empfehlen kann, die am besten für Sie geeignet ist."
    />
            <Container>
                <Row>
                    <Col sm="12">
                        <h1 className="mt-5 mb-5 text-center">
                        Wohnungsbau
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <p className="flow-text">
                        Dank der kompakten Produkte mit dem günstigen Preis-Leistungs-Verhältnis von Bike Safe muss die Unterbringung von Fahrrädern den Wohnungsbauunternehmen ab sofort kein Kopfzerbrechen mehr bereiten. Sprechen Sie mit einem unserer Vertreter, der Ihnen die Lösung empfehlen kann, die am besten für Sie geeignet ist.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <StaticImage placeholder="blurred"  src="../images/bikestand.jpg" className="shadow mt-5 mb-5 fluid rounded" />
                        <p>
                            <em className="flow-text">"Wir waren voll und ganz mit Ihrem Service zufrieden. Und das ist in diesen unruhigen Zeiten wirklich erfrischend!"
                                <strong> James Whiteley (Einkäufer) Sir Robert Mcalpine.</strong>.</em></p>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default Builders;